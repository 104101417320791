h3 {
  text-align: center;
  font-size: 20px;
}

.mainGrid {
  max-width: 1024px;
}

.row {
  margin: 20px 0 0;
}

.inlineForm > div > div {
  display: inline;
}

.center-block {
  float: none;
}

.inlineForm input {
  width: 100px;
}

.inlineForm .datePicker, .inlineForm .custom-select {
  margin: 0 10px;
}

.inlineForm .form-group {
  padding-right: 20px;
}

.chartjs-render-monitor {
  /*    max-height: 250px;*/
}
