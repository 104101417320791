#previewBlock {
  /*  position: fixed;*/
}

#logoPreview {
  width: 50px;
  height: 50px;
  margin-right: 15px;
}

.container {
  flex: 1;
  flex-direction: "row";
  display: block;
  position: relative;
  width: 100%;

  height: 50px;
  vertical-align: middle;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
}

.prevTitle {
  color: black;
  font-weight: bold;
  font-size: 15px;
  margin: 14px;
  margin-left: 0;
  margin-right: 0;
}

.prevPlace {
  font-size: 12px;
  margin-left: 10px;
}

#titlePrev {
  clear: both;
}

.rt-noData {
  z-index: 10;
}

#contentPreview {
  clear: both;
  width: 100%;
  margin-top: 20px;
}

#contentPreview h5 {
  text-align: center;
  width: 100%;
  font-size: 1.1em;
  min-height: 1.2em;
}

#contentPreview #textPreview {
  text-align: center;
  width: 100%;
  font-size: 1em;
}

.contentBottom {
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contentBottom #address {
}

.contentBottom #buttonCTA {
  background: #004466;
  color: white;
  font-weight: bold;
  padding: 7px 20px;
  border-radius: 10px;
  margin: 2px;
  text-align: center;
}

#adPicPreview {
  width: 100%;
  background-size: cover;
  border-radius: 13px;
}

#conditions {
  clear: both;
  text-align: center;
  color: gray;
  font-size: 0.85em;
  margin-top: 10px;
}

#formSend {
  clear: both;
  margin-top: 30px;
  text-align: center;
}

#formSend #sendButton {
  width: 50%;
  font-weight: bold;
}


.file-upload-button {
  background-color: red;
}

.loadingScreen {
  display: flex;
  position: absolute;

  width: 100%;
  height: 100%;
  
  top: 0;
  left: 0;

  justify-content: center;
  align-items: center;
  z-index: 10;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(10px);
}

.placeholderComponent {
  color: lightslategray;
}

.carousel {
  background-color: teal;
  color: white;
  border-radius: 10px;
}

.carousel .placeholderComponent {
  color: rgba(255, 255, 255, 0.5);
}

.alice-carousel__prev-btn-item, .alice-carousel__next-btn-item, .alice-carousel__prev-btn-item:hover, .alice-carousel__next-btn-item:hover {
  color: white;
}

.alice-carousel__prev-btn, .alice-carousel__next-btn {
  text-align: center;
}

.slide {
  min-height: 460px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.slide h5 {
  margin: 20px 0;
}